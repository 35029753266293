<template>
  <moe-page title="编辑问题">
    <moe-form ref="questionForm" :model="questionParams" :rules="rules">
      <el-form-item label="问题分类" prop="assortId">
        <el-select v-model="questionParams.assortId" placeholder="请选择问题分类">
          <el-option v-for="(item, index) in questionList" :key="index" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>

      <el-form-item label="设备明细">
        <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="handleClick">绑定设备</el-button>
        <div class="df aic fww">
          <moe-tag
            class="mr-10"
            v-for="(item, index) in questionParams.deviceModelList"
            :key="index"
            :closable="true"
            @close="handleClose(index)">
            {{ index + 1 }}、{{ `【${item.productModel}】${item.name}` }}
          </moe-tag>
        </div>
      </el-form-item>

      <el-form-item label="问题" prop="problem">
        <el-input v-model.trim="questionParams.problem" placeholder="请输入问题" maxlength="30" clearable />
      </el-form-item>

      <el-form-item label="标签" prop="tags">
        <div class="df aic fww">
          <moe-tag class="mr-10 mb-10" v-for="(item, index) in questionParams.tags" :key="index" closable @close="handleTagsClose(index)">{{ item }}</moe-tag>

          <template v-if="showInput">
            <el-input
              ref="saveTagInput"
              class="mr-10 mb-10 w-150"
              type="text"
              placeholder="输入标签"
              v-model="inputValue"
              size="small"
              @keyup.enter.native="handleTagsConfirm()"
              @blur="handleTagsConfirm()"
              clearable>
            </el-input>
          </template>
          <div class="mb-10" v-else>
            <el-button class="button-new-tag" size="mini" @click="handleTagsShow()">+ 添加</el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="解答" prop="answer">
        <moe-tinymce v-model="questionParams.answer"></moe-tinymce>
      </el-form-item>

      <template slot="tool">
        <el-button :loading="questionLoad" type="primary" @click="handleSubmit()">{{ questionLoad ? '请稍候' : '保存' }}</el-button>
      </template>
    </moe-form>

    <moe-dialog :show="showDialog" title="绑定设备" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-inquire @search="deviceSearch">
          <el-form-item label="设备名称">
            <el-input placeholder="输入设备名称" v-model.trim="deviceParams.name" clearable />
          </el-form-item>
          <el-form-item label="设备型号">
            <el-select v-model="deviceParams.productModel" filterable placeholder="选择设备型号" clearable>
              <el-option v-for="(item, index) in deviceList" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </moe-inquire>
        <moe-table
          v-if="showDialog"
          :maxHeight="480"
          :defaultSelectIds="questionParams.deviceModelList && questionParams.deviceModelList.length ? questionParams.deviceModelList.map(({ id }) => id) : []"
          ref="deviceTable"
          rowKey="id"
          url="/deviceModel/paging"
          :params="deviceParams"
          :number-show="false"
          @selectChange="selectChange">
          <el-table-column type="selection" reserve-selection label="id" width="60" />
          <el-table-column prop="name" label="设备名称" min-width="130" />
          <el-table-column prop="productModel" label="设备型号" />
          <el-table-column prop="wireless" label="无线类型">
            <template slot-scope="{ row }">
              {{ wirelessComputed[row.wireless] }}
            </template>
          </el-table-column>
          <el-table-column prop="enable" label="状态">
            <template slot-scope="{ row }">
              {{ row.enable ? '启用' : '禁用' }}
            </template>
          </el-table-column>
          <el-table-column label="设备图片">
            <template slot-scope="{ row }">
              <moe-image :src="row.homeImage" width="100px" height="50px" />
            </template>
          </el-table-column>
          <el-table-column prop="empty" label="通用图片">
            <template slot-scope="{ row }">
              <moe-image :src="row.image" width="100px" height="50px" />
            </template>
          </el-table-column>
        </moe-table>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取 消</el-button>
        <el-button type="primary" @click="handleDeviceConfirm()">确 定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'FqaManageQuestionAmend',
  data() {
    const verify = this.$moe_verify.verifyForm
    const rules = {
      assortId: verify.isEmpty('请选择问题分类'),
      problem: verify.isEmpty('请输入问题'),
      answer: verify.isEmpty('请输入解答'),
    }
    return {
      questionList: [],
      questionParams: {
        assortId: '',
        deviceModelList: [],
        problem: '',
        tags: [],
        answer: '',
      },
      rules,
      questionLoad: false,
      showDialog: false,
      deviceList: [],
      deviceParams: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        productModel: '',
      },
      selectList: [],
      showInput: false,
      inputValue: '',
    }
  },
  computed: {
    wirelessComputed() {
      let options = {}
      if (!this.wirelessList) {
        return '-'
      }

      for (let key in this.wirelessList) {
        options[this.wirelessList[key]] = key
      }

      return options
    }
  },
  methods: {
    deviceSearch(isSearch) {
      if (!isSearch) {
        this.deviceParams = {
          pageNum: 1,
          pageSize: 10,
          name: '',
          productModel: '',
        }
      }

      this.$refs.deviceTable.searchData();
      this.$refs.deviceTable.clearSelect();
    },
    /** 添加Tags */
    handleTagsConfirm() {
      if (!this.inputValue) {
        return this.$moe_msg.warning('请输入内容 !')
      }
      let findItem = this.questionParams.tags.find((name) => this.inputValue === name);
      if (!findItem) {
        this.questionParams.tags.push(this.inputValue);
      }
      this.showInput = false;
      this.inputValue = '';
    },
    handleTagsShow() {
      this.showInput = true;
      this.$nextTick(() => {
        this.$refs['saveTagInput'].$refs.input.focus();
      })
    },
    /** 删除Tags */
    handleTagsClose(index) {
      this.questionParams.tags.splice(index, 1)
    },
    handleClick() {
      this.showDialog = true;
    },
    handleDialogClose() {
      this.deviceSearch(false);
      this.showDialog = false;
    },
    handleClose(index) {
      this.questionParams.deviceModelList.splice(index, 1);
      this.$forceUpdate();
    },
    selectChange(list) {
      this.selectList = list;
    },
    handleDeviceConfirm() {
      this.selectList.forEach((item) => {
        let findItem = this.questionParams.deviceModelList.find(({ id }) => item.id === id)

        if (!findItem) {
          this.questionParams.deviceModelList.push(item)
        }
      })
      this.handleDialogClose();
    },
    handleSubmit() {
      this.$refs['questionForm'].validate(() => {
        this.questionLoad = true;
        let params = this.$moe_lodash.cloneDeepData(this.questionParams);

        if (this.questionParams.deviceModelList && this.questionParams.deviceModelList.length) {
          params.deviceModelList = this.questionParams.deviceModelList.map(({ id }) => {
            return {
              delFlag: 0,
              deviceModelId: id,
            }
          })
        }

        this.$moe_api.FQA_API.updateProblem(params).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('编辑问题成功 !', {
              completionHandler: () => {
                this.$moe_coordinator.navigateBack();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.questionLoad = false;
        })
      })
    },
    /** 常见问题详情 */
    queryCommonProblem() {
      if (this.$route.query.id) {
        this.$moe_api.FQA_API.queryCommonProblem({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let r = data.result;
            this.questionParams = {
              id: r.id,
              assortId: r.assortId,
              problem: r.problem,
              tags: r.tags,
              answer: r.answer,
              deviceModelList: [],
            };

            if (r.deviceModelList.length) {
              this.questionParams.deviceModelList = r.deviceModelList.map((item) => {
                return {
                  ...item,
                  name: item.deviceName,
                  type: item.id,
                  id: item.deviceModelId,
                }
              })
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
  },
  mounted() {
    /** 设备类型可用参数 */
    this.$moe_api.EQUIPMENT_API.getDeviceModelParams().then((data) => {
      if (data.code === 200) {
        this.wirelessList = data.result.wireless;
      } else {
        this.$moe_msg.error(data.message)
      }
    })
    /** 问题分类管理列表 */
    this.$moe_api.FQA_API.assortList().then((data) => {
      if (data.code === 200) {
        this.questionList = data.result.map(({ assort, id }) => {
          return {
            label: assort,
            value: id,
          }
        })
      } else {
        this.$moe_msg.error(data.message);
      }
    })
    /** 查看所有设备型号 */
    this.$moe_api.EQUIPMENT_API.readAll().then((data) => {
      if (data.code === 200) {
        this.deviceList = data.result.map(({ name, productModel }) => {
          return {
            label: `【${productModel}】${name}`,
            value: productModel
          }
        })
      }
    })

    this.queryCommonProblem();
  },
}
</script>

<style lang="scss">
</style>